<template>
  <nav :class="navClass">
    <router-link to="/">首页</router-link>
    <router-link to="/about">关于我们</router-link>
    <router-link to="/core">核心产品</router-link>
    <router-link to="/contact">联系我们</router-link>
  </nav>
  <router-view />
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

// 计算 nav 的背景颜色类
const navClass = computed(() => {
  return {
    'nav-home': route.path === '/',
    'nav-about': route.path === '/about',
    'nav-core': route.path === '/core',
    'nav-contact': route.path === '/contact'
  }
})
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    margin-right: 50px; /* 每个链接之间的间距 */
    text-decoration: none;
    font-weight: bold;
    position: relative;
    display: inline-block;
  }

  a:last-child {
    margin-right: 0; /* 最后一个链接不要添加右边距 */
  }

  &.nav-home {
    // width: 100%;
    height: 94vh; /* 占满整个屏幕高度 */
    // background-color: red;
    background-image: url('./assets/home.png'); /* 背景图片路径 */
    background-size: cover; /* 确保背景图片占满屏幕 */
    // background-position: center; /* 背景图片居中显示 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
    overflow: hidden; /* 禁用滑动 */
    transition: background-color 0.3s;
    a.router-link-active::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      height: 4px;
      width: 100%;
      border-radius: 3px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease-in-out;
    }

    a.router-link-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &.nav-about {
    // width: 100%;
    height: 83vh; /* 占满整个屏幕高度 */
    // background-color: red;
    background-image: url('./assets/aboutUs.png'); /* 背景图片路径 */
    background-size: cover; /* 确保背景图片占满屏幕 */
    // background-position: center; /* 背景图片居中显示 */
    background-repeat: no-repeat; /* 防止背景图片重复 */
    overflow: hidden; /* 禁用滑动 */
    transition: background-color 0.3s;
    a.router-link-active::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      margin-left: 15px;
      height: 4px;
      width: 32px;
      border-radius: 3px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease-in-out;
    }

    a.router-link-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &.nav-core {
    // height: 83vh; /* 占满整个屏幕高度 */
    // background-color: red;
    transition: background-color 0.3s;
    a.router-link-active::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      margin-left: 15px;
      height: 4px;
      width: 32px;
      border-radius: 3px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease-in-out;
    }

    a.router-link-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }
  &.nav-contact {
    transition: background-color 0.3s;

    a.router-link-active::after {
      content: '';
      position: absolute;
      left: 0;
      margin-left: 15px;
      bottom: -5px;
      height: 4px;
      width: 32px;
      border-radius: 3px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.3s ease-in-out;
    }

    a.router-link-active {
      &::after {
        transform: scaleX(1);
      }
    }
  }

  &.nav-home a {
    color: white;
  }

  &.nav-about a,
  &.nav-core a,
  &.nav-contact a {
    color: black;
  }

  &.nav-about a.router-link-active::after,
  &.nav-core a.router-link-active::after,
  &.nav-contact a.router-link-active::after {
    background-color: red;
  }

  &.nav-home a.router-link-active::after {
    background-color: white;
  }
}
</style>
